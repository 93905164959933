window.glLazy = '';
module.exports = {
	lazyInit: function (){
		window.glLazy = $('.lazy').Lazy({
			chainable: false,
			threshold: 600,
			effect: 'fadeIn',
			effectTime: 500,
			throttle: 150,
			bind: 'event',
			placeholder: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAARgAA/+4ADkFkb2JlAGTAAAAAAf/bAIQABAMDAwMDBAMDBAYEAwQGBwUEBAUHCAYGBwYGCAoICQkJCQgKCgwMDAwMCgwMDQ0MDBERERERFBQUFBQUFBQUFAEEBQUIBwgPCgoPFA4ODhQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQU/8AAEQgACgAKAwERAAIRAQMRAf/EAEsAAQEAAAAAAAAAAAAAAAAAAAAIAQEAAAAAAAAAAAAAAAAAAAAAEAEAAAAAAAAAAAAAAAAAAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCygAAAf//Z",
			onError: function(element) {
	            console.log('error loading ' + element.data('src'));
	        }
		});  
	},
	/*lazyUpdate: function (){
		console.log(window.glLazy)
		window.glLazy.update();
	}*/
}

