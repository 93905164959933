var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.jQuery = require('jquery');

var flexsliderLoad = require('flexslider'),
	flexslider = require('../modules/slider'),
	slickInit = require('slick-carousel');
	imgLoader = require('../modules/lazy'),

page_home = {
	load: function(){
		// this.selectStyle();
		this.homeSlider();
		this.appSlider();
		this.teamSliderInit();
		this.serviziSliderInit();
		// this.flexslider();
	},
	// flexslider: function(){
	// 	slider = $('.homeVisual');
	// 	slider.each(function() {
	// 		flexslider.visualFull(slider);
	// 	});
	// },
	// selectStyle: function(){
	// 	$('select.filter').selectize({
	// 		closeAfterSelect: true
	// 	});
	// },

	homeSlider: function(){
		jQuery('.homeVisual').slick({
			lazyLoad: 'ondemand',
			centerMode: false,
		  	arrows: true,
		  	slidesToShow: 1,
		  	autoplay: true,
		  	autoplaySpeed: 7500,
		  	centerPadding: 0,
		  	prevArrow: '<span class="slick-prev"></span>',
		  	nextArrow: '<span class="slick-next"></span>',
		});
	},
	appSlider: function(){
		jQuery('.homeProp__list').slick({
			// lazyLoad: 'ondemand',
			centerMode: false,
		  	arrows: true,
		  	slidesToShow: 3,
		  	autoplay: true,
		  	autoplaySpeed: 4500,
		  	centerPadding: 0,
		  	prevArrow: '<span class="slick-prev"></span>',
		  	nextArrow: '<span class="slick-next"></span>',
		  	responsive: [
		  		{
		  			breakpoint: 991,
		  			settings: {
		  				slidesToShow: 2
		  			}
		    	},
		    	{
		  			breakpoint: 480,
		  			settings: {
		  				slidesToShow: 1
		  			}
		    	}
		  	]
		});
	},
	teamSliderInit: function(){
		jQuery('.homeTeam__wrap').slick({
			// lazyLoad: 'ondemand',
			centerMode: false,
		  	arrows: true,
		  	slidesToShow: 3,
		  	autoplay: false,
		  	autoplaySpeed: 4500,
		  	centerPadding: 0,
		  	prevArrow: '<span class="slick-prev"></span>',
		  	nextArrow: '<span class="slick-next"></span>',
		  	responsive: [
		  		{
		  			breakpoint: 991,
		  			settings: {
		  				slidesToShow: 2
		  			}
		    	},
		  		{
		  			breakpoint: 480,
		  			settings: {
		  				slidesToShow: 1
		  			}
		    	}
		  	]
		});
	},
	serviziSliderInit: function(){
		jQuery('.homeServizi__wrap').slick({
			centerMode: true,
			arrows: true,
			slidesToShow: 2,
			// autoplay: true,
			autoplaySpeed: 4500,
			centerPadding: 0,
		  	prevArrow: '<span class="slick-prev"></span>',
		  	nextArrow: '<span class="slick-next"></span>',
		  	responsive: [
		  		{
		  			breakpoint: 991,
		  			settings: {
		  				slidesToShow: 2
		  			}
		    	},
		  		{
		  			breakpoint: 480,
		  			settings: {
		  				slidesToShow: 1
		  			}
		    	}
		 	]
		});
	},
}
$(window).on('load', function(){
	page_home.load();
})